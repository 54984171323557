import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)



// nav functionality
const navParents = document.querySelectorAll(".nav-parent")
const navChildren = document.querySelectorAll(".nav-children")

for (let i = 0; i < navParents.length; i++) {
    const el = navParents[i];
    
    el.addEventListener("mouseover", () => {
        navChildren[i].classList.add("nav-child-active")
    })
    el.addEventListener("mouseout", () => {
        navChildren[i].classList.remove("nav-child-active")
    })
}

// Testimonials Swiper
const testimonialSwiper = new Swiper('.testimonial-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
    breakpoints: {
    }
    
  });

// initial nav background
const banner = document.getElementById("banner")
const header = document.getElementById("header")

if(banner && banner.classList.contains("transparent-nav")) {
    header.classList.remove("bg-black")
}

// scrollback functionality
function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }



  var current = window.scrollY
  var paddedUp = 0
  var paddedDown = 0
  function saveInput(){
      
    if(window.scrollY > 200) {
      if(window.scrollY < current){
        if(window.scrollY < paddedUp) {
        header.classList.remove("scroll-down")
        header.classList.add("scroll-up")
        }
        paddedDown = window.scrollY + 100
        
      } else {
        if(window.scrollY > paddedDown) {
          header.classList.remove("scroll-up")
          header.classList.add("scroll-down")
          setTimeout(() => {
            header.classList.add("bg-black")
          }, 300);
          paddedUp = window.scrollY - 100
        }
       
      }
      current = window.scrollY
    } else {
      header.classList.remove("scroll-down")
      header.classList.add("scroll-up")
      header.classList.remove("bg-black")
    }
   
  }

const logoScroll = debounce(() => saveInput());
if(banner && banner.classList.contains("transparent-nav")) {
window.addEventListener("scroll", logoScroll);
}

// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
  navEx.addEventListener("click", function() {
      if(mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.remove("mobile-nav-active")
        
      }   
  })
}
if(navBtn){
navBtn.addEventListener("click", function() {
    if(!mobileNav.classList.contains("mobile-nav-active")) {
      mobileNav.classList.add("mobile-nav-active")
      
    }
})
}

const mobileNavParents = document.querySelectorAll(".mobile-nav-parent")

for (let i = 0; i < mobileNavParents.length; i++) {
  const element = mobileNavParents[i];
  let test = false
  element.addEventListener("click", (e) => {
    if(test){
      
    } else {
      e.preventDefault()
      test = true
    }
    
  })
  
}


// Main fade scroll trigger animation for Supergiant
function animateFrom(elem) {
  var x = 0,
  y = 20,
  delay = .3
if(elem.classList.contains("gs_reveal_fromLeft")) { 
x = -20,
y = 0,
delay = .5
} else if(elem.classList.contains("gs_reveal_fromRight")) {
x = 20,
y = 0,
delay = .5
}else if(elem.classList.contains("gs_reveal_projectSub")) {
  delay = .8
}else if(elem.classList.contains("gs_reveal_projectSubTwo")) {
  delay = .3
}else if(elem.classList.contains("gs_reveal_stagger_1")) {
  delay = .3
}else if(elem.classList.contains("gs_reveal_stagger_2")) {
  delay = .6
}else if(elem.classList.contains("gs_reveal_stagger_3")) {
  delay = .9
}else if(elem.classList.contains("gs_reveal_stagger_4")) {
  x = 0,
  y = 20,
  delay = 1.1
}else if(elem.classList.contains("gs_reveal_stagger_5")) {
  x = 0,
  y = 0,
  delay = 1.3
}else if(elem.classList.contains("gs_reveal_stagger_6")) {
  x = 0,
  y = 0,
  delay = 1.5
}else if(elem.classList.contains("gs_reveal_stagger_7")) {
  x = 0,
  y = 0,
  delay = 1.7
}else if(elem.classList.contains("gs_reveal_staggerOneBanner")) {
  delay = .5
  x = 0,
  y = direction * 50
  
}
else if(elem.classList.contains("gs_reveal_staggerTwoBanner")) {
  delay = .8
  x = 0,
  y = direction * 50
}else if(elem.classList.contains("gs_reveal_staggerThreeBanner")) {
  delay = 1.1
  x = 0,
  y = direction * 50
}

gsap.fromTo(elem, {x: x, y: y, opacity: 0}, {
duration: 1.5, 
x: 0,
y: 0, 
delay: delay,
opacity: 1, 
ease: "expo", 
overwrite: "auto"
});
}


var reveals = gsap.utils.toArray(".gs_reveal");
  for(var i = 0; i < reveals.length; i++) {
  (function () {
  var elem = reveals[i];
  //   hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      
      once: true,
      onEnter: function() { animateFrom(elem) }, 
      
    });
  })();
  } 


  function disableScroll() {
    // To get the scroll position of current webpage
    let TopScroll = window.scrollY || document.documentElement.scrollTop;
    let LeftScroll = window.scrollX || document.documentElement.scrollLeft;
    
    // if scroll happens, set it to the previous value
    window.onscroll = function() {
    window.scrollTo(LeftScroll, TopScroll);
     };
  }
    
    function enableScroll() {
    window.onscroll = function() {};
    }

  const leavingModal = document.getElementById("leaving-site-modal")
  const leavingBtn = document.getElementById("leave-btn")
  const exitBtns = document.querySelectorAll(".modal-exit")

  let links = Array.from(document.links)

  for (let i = 0; i < links.length; i++) {
    const el = links[i];
    if(el.href.includes(window.location.hostname)) {   
    } else if(el.href.includes('http')){
      el.onclick = function(e) {
        e.preventDefault()
        leavingModal.classList.remove("hidden")
        leavingModal.classList.add("flex")
        
        disableScroll()
  
        leavingBtn.href = el.href
        
      }
    }
  }

  // exit buttons
for (let i = 0; i < exitBtns.length; i++) {
  const el = exitBtns[i];
  el.onclick = function() {
   
    leavingModal.classList.add("hidden")
    leavingModal.classList.remove("flex")
    enableScroll()
  }
}

window.onclick = function(event) {
  if (event.target == leavingModal) {
    leavingModal.classList.add("hidden")
    leavingModal.classList.remove("flex")
  
    enableScroll()
  }
}
